import { Phone, Mail, MapPin } from 'lucide-react';
import { useState } from 'react';
import Section from './ui/Section';
import Card from './ui/Card';
import Button from './ui/Button';

export default function Contact() {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');

  const encode = (data: Record<string, string>) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus('idle');

    try {
      const response = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "contact",
          ...formState
        })
      });

      if (response.ok) {
        setSubmitStatus('success');
        setFormState({ name: '', email: '', message: '' });
      } else {
        setSubmitStatus('error');
      }
    } catch (error) {
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <Section id="kontakt" className="bg-gray-50 dark:bg-gray-900 pt-16 pb-20">
      <div className="text-center">
        <h2 className="text-3xl font-bold text-gray-900 dark:text-white sm:text-4xl">Kontaktirajte nas</h2>
        <p className="mt-4 text-xl text-gray-600 dark:text-gray-400">Tu smo za sva vaša pitanja</p>
      </div>

      <div className="mt-16 grid grid-cols-1 gap-8 md:grid-cols-2">
        <Card>
          <form 
            className="p-8 space-y-6" 
            onSubmit={handleSubmit}
            data-netlify="true"
            name="contact"
            method="POST"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p className="hidden">
              <label>
                Don't fill this out if you're human: <input name="bot-field" />
              </label>
            </p>
            
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Ime i prezime
              </label>
              <input 
                type="text" 
                id="name"
                name="name"
                value={formState.name}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-white shadow-sm focus:border-blue-500 focus:ring-blue-500" 
                aria-label="Vaše ime i prezime"
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Email adresa
              </label>
              <input 
                type="email" 
                id="email"
                name="email"
                value={formState.email}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-white shadow-sm focus:border-blue-500 focus:ring-blue-500" 
                aria-label="Vaša email adresa"
              />
            </div>

            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Poruka
              </label>
              <textarea 
                id="message"
                name="message"
                value={formState.message}
                onChange={handleChange}
                required
                rows={4} 
                className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
                aria-label="Vaša poruka"
              ></textarea>
            </div>

            {submitStatus === 'success' && (
              <div className="p-4 bg-green-100 dark:bg-green-900 text-green-700 dark:text-green-100 rounded-md">
                Poruka je uspješno poslana! Kontaktirat ćemo vas uskoro.
              </div>
            )}

            {submitStatus === 'error' && (
              <div className="p-4 bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-100 rounded-md">
                Došlo je do greške. Molimo pokušajte ponovno ili nas kontaktirajte telefonom.
              </div>
            )}

            <Button 
              type="submit" 
              variant="primary" 
              fullWidth
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Slanje...' : 'Pošalji poruku'}
            </Button>
          </form>
        </Card>

        <Card>
          <div className="p-8 space-y-8">
            <div itemScope itemType="http://schema.org/LocalBusiness">
              <meta itemProp="name" content="GSWISP Internet" />
              <div className="flex items-start">
                <Phone className="h-6 w-6 text-blue-600 dark:text-blue-400 mt-1" aria-hidden="true" />
                <div className="ml-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">Telefon</h3>
                  <p className="mt-1">
                    <a 
                      href="tel:+385985353333" 
                      className="text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
                      itemProp="telephone"
                    >
                      098 535 333
                    </a>
                  </p>
                  <p className="mt-1">
                    <a 
                      href="tel:+385984974777" 
                      className="text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
                      itemProp="telephone"
                    >
                      098 497 477
                    </a>
                  </p>
                </div>
              </div>

              <div className="flex items-start">
                <Mail className="h-6 w-6 text-blue-600 dark:text-blue-400 mt-1" aria-hidden="true" />
                <div className="ml-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">Email</h3>
                  <p className="mt-1">
                    <a 
                      href="mailto:meridian.arts2@gmail.com" 
                      className="text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
                      itemProp="email"
                    >
                      meridian.arts2@gmail.com
                    </a>
                  </p>
                  <p className="mt-1">
                    <a 
                      href="mailto:info@gswisp.net" 
                      className="text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
                      itemProp="email"
                    >
                      info@gswisp.net
                    </a>
                  </p>
                </div>
              </div>

              <div className="flex items-start">
                <MapPin className="h-6 w-6 text-blue-600 dark:text-blue-400 mt-1" aria-hidden="true" />
                <div className="ml-4" itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">Adresa</h3>
                  <p className="mt-1 text-gray-600 dark:text-gray-400">
                    <span itemProp="streetAddress">Popa Frana Biničkog 12</span><br />
                    <span itemProp="postalCode">53000</span> <span itemProp="addressLocality">Gospić</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Section>
  );
}