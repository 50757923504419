import React from 'react';

const scrollingText = `
Domaći optički internet u stambenim zgradama:
Budite među prvima, koji će dobiti ultra brzi domaći optički internet.
Povećajte vrijednost vaše nekretnine.
Ukoliko ne želite čekati neodređeno dugo vremena na uvođenje brzog optičkog interneta u vašu zgradu, GSWISP vam već danas nudi brzo rješenje.
Sve što trebate napraviti je:
Iskazivanje interesa za izgradnju optičke mreže u vašoj zgradi, pozivom na 098 535 333 ili na e-mail te zatim potpisivanjem suglasnosti kod Predstavnika stanara.
Nakon toga se potpisuje ugovor s Operatorom zgrade te se odmah pristupa izgradnji optičke infrastrukture.
Svjetlovodna nit će biti dovedena u svaki stan.
Izgradnja optike u zgradi za stanare je besplatna.
Troškove izgradnje snosi Operator zgrade.
Nakon toga možete zatražiti i očekivati spajanje preko brzog interneta odabirom GSWISP-a ili bilo kojeg drugog operatera.
Dobrodošli u najbrže rastuću mrežu!
Cijenom povoljan, pouzdan, brzi Internet je dostupan kod Vas upravo sada.
GSWISP Internet je nova mogućnost za sve Vas koji nemate previše mogućnosti izbora za pristup Internetu.
U ruralnim i urbanim sredinama, gdje ne postoji ili nije kvalitetan i povoljan pristup brzom internetu.
NAPOKON JE STIGAO INTERNET KOJEGA ZASLUŽUJETE!
BUDUĆNOST NE MOŽE ČEKATI, A NAMA JE BITAN SVAKI KORISNIK!
OSIM GSWISP INTERNETA PO SUPER CIJENI 13,14 EUR / MJESEČNO, IMAMO JOŠ NOVITETA ZA KORISNIKE:
OD SADA MOŽETE KORISTITI VOIP TELEFONIRANJE PO CIJENI 0,03 centa / minuta PREMA FIKSNIM BROJEVIMA U HRVATSKOJ!!!
MOGUĆE JE PRENIJETI POSTOJEĆI TELEFONSKI BROJ ILI UZETI NOVI BROJ IZ 053 NUMERACIJE.
(REZERVIRALI SMO BROJEVE ZA NOVE KORISNIKE)
MJESEČNA CIJENA ZA VOIP JE SAMO 1,33 EUR / MJESEČNO!!!
`;

export default function ScrollingText() {
  return (
    <div className="w-full bg-gray-900 dark:bg-black py-2 overflow-hidden fixed top-16 z-40">
      <div className="relative flex h-8">
        <div className="animate-scroll whitespace-nowrap text-white text-base px-4">
          {scrollingText.repeat(2)}
        </div>
      </div>
    </div>
  );
}