import React from 'react';
import { SectionTitle } from './sections/SectionTitle';
import PackageGrid from './packages/PackageGrid';
import { wirelessPackages } from '../data/packages';
import Section from './ui/Section';

export default function WirelessInternet() {
  return (
    <Section id="bezicni" className="pt-16 pb-20 bg-white dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <SectionTitle 
          title="Fiksni bežični GSWISP internet"
          subtitle="Pouzdana bežična veza bez ugovorne obveze"
          titleClassName="text-gray-900 dark:text-white"
          subtitleClassName="text-gray-600 dark:text-gray-400"
        />
        <PackageGrid 
          packages={wirelessPackages} 
          speedUnit="Mbits"
          icon="wifi"
        />
      </div>
    </Section>
  );
}