import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import ScrollingText from '../components/ScrollingText';
import Hero from '../components/Hero';
import InternetPackages from '../components/InternetPackages';
import FiberServices from '../components/FiberServices';
import WirelessInternet from '../components/WirelessInternet';
import Coverage from '../components/Coverage';
import AboutSection from '../components/AboutSection';
import Contact from '../components/Contact';
import Flyers from '../components/Flyers';
import Footer from '../components/Footer';

export default function HomePage() {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        const navbarHeight = 64;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  return (
    <>
      <Navbar />
      <div className="pt-16">
        <ScrollingText />
        <Hero />
        <InternetPackages />
        <FiberServices />
        <WirelessInternet />
        <Coverage />
        <AboutSection />
        <Contact />
        <Flyers />
        <Footer />
      </div>
    </>
  );
}