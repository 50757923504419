import React from 'react';
import Section from './ui/Section';
import { Building2, Target, Map, Users, Clock, Shield } from 'lucide-react';

export default function AboutSection() {
  const features = [
    {
      icon: Building2,
      title: 'Domaći provider',
      description: 'Prvi domaći pružatelj pristupa internetu iz Gospića s 11 godina iskustva.'
    },
    {
      icon: Users,
      title: 'Zadovoljni korisnici',
      description: 'Preko 200 stalnih korisnika koji nam vjeruju i preporučuju nas.'
    },
    {
      icon: Target,
      title: 'Naša misija',
      description: 'Stvaranje kvalitetnog i povoljnog pristupa internetu za sve građane Gospića i okolice.'
    },
    {
      icon: Map,
      title: 'Područje djelovanja',
      description: 'Gospić i okolna naselja, s kontinuiranim širenjem mreže.'
    },
    {
      icon: Clock,
      title: 'Kontinuirani razvoj',
      description: 'Od 2014. godine kontinuirano razvijamo i unapređujemo našu mrežu.'
    },
    {
      icon: Shield,
      title: 'Pouzdanost',
      description: 'Ne dajemo obećanja koja ne možemo ispuniti.'
    }
  ];

  return (
    <Section id="o-nama" className="bg-white dark:bg-gray-900 pt-8 pb-20">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-gray-900 dark:text-white mb-12">O nama</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
            >
              <div className="flex items-center mb-4">
                <feature.icon className="h-8 w-8 text-blue-600 dark:text-blue-400 mr-3" />
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {feature.title}
                </h3>
              </div>
              <p className="text-gray-600 dark:text-gray-400">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        <div className="prose prose-lg dark:prose-invert max-w-4xl mx-auto">
          <p className="text-gray-600 dark:text-gray-400 mb-6">
            <strong className="text-white font-bold">Domaća tvrtka MERIDIAN ARTS iz Gospića</strong> pružatelj je telekomunikacijskih usluga povoljnog pristupa Internetu baziranog na GSWISP vlastitoj bežičnoj i optičkoj mreži nove generacije.
          </p>
          
          <p className="text-gray-600 dark:text-gray-400 mb-6">
            S obzirom na postojeće stanje nepokrivenosti pojedinih dijelova grada kvalitetnom uslugom pristupa internetu, vrlo je izgledno kako će takvo stanje ostati još dosta dugo vremena zbog nikakvog interesa postojećih telekom operatora za uvođenje usluga pristupa širokopojasnom internetu ili poboljšanje kvalitete postojećih usluga.
          </p>
          
          <p className="text-gray-600 dark:text-gray-400 mb-6">
            Stoga smo se odlučili za pokretanje izgradnje vlastite bežične infrastrukture u urbanim i ruralnim područjima grada Gospića i obližnjih prigradskih naselja.
          </p>
          
          <div className="mt-8">
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Naša ponuda</h3>
            <ul className="space-y-2">
              <li className="flex items-start">
                <span className="text-blue-600 dark:text-blue-400 mr-2">•</span>
                <span className="text-gray-600 dark:text-gray-400">Pristup bežičnom internetu bez telefonske linije</span>
              </li>
              <li className="flex items-start">
                <span className="text-blue-600 dark:text-blue-400 mr-2">•</span>
                <span className="text-gray-600 dark:text-gray-400">Pristup optičkom internetu u stambenim zgradama</span>
              </li>
              <li className="flex items-start">
                <span className="text-blue-600 dark:text-blue-400 mr-2">•</span>
                <span className="text-gray-600 dark:text-gray-400">Bez ugovorne obveze</span>
              </li>
              <li className="flex items-start">
                <span className="text-blue-600 dark:text-blue-400 mr-2">•</span>
                <span className="text-gray-600 dark:text-gray-400">Vrlo povoljna instalacija za optički internet u stanu ili vašem domu</span>
              </li>
              <li className="flex items-start">
                <span className="text-blue-600 dark:text-blue-400 mr-2">•</span>
                <span className="text-gray-600 dark:text-gray-400">Niže cijene, a uz veću kvalitetu usluga</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Section>
  );
}